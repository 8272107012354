/* General styles */

:-ms-input-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  letter-spacing: 0.636364px;
  color: var(--color-ui-text-placeholder);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --color-ui-primary: #0ca2fe;
  --color-ui-primary-rgba: rgba(12, 162, 254, 0.1);
  --color-ui-light-blue: #e8f1ff;
  --color-ui-navbar-dark: #465364;
  --color-ui-navbar-medium: #5f6f85;
  --color-ui-dark: #2e2e2f;
  --color-ui-red: #f37676;
  --color-ui-orange: #ce7609;
  --color-ui-warning: #FD9B5B;
  --color-ui-warning-light: #fff5ef;
  --color-ui-text-light: #797f83;
  --color-ui-input-bg: #F5F5F5;
  --color-ui-select-bg: #F0F2F5;
  --color-ui-text-placeholder: #9c9ea0;
  --color-ui-table-group-bg: #d0d4d8;
  --color-ui-button-light-bg: #f2f2f2;
  --color-ui-block-border: #a7afba;
  --color-ui-admin: #555;
  --color-ui-category-color-2: #58cb7d;
}

/* ------------------------------------------- titles and texts ------------------------------------------- */
.custom-modal-body {
  overflow-y: auto;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.block-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.646465px;
  color: var(--color-ui-dark);
  border: 0;
  border-bottom: 1px solid var(--color-ui-block-border);
}

.block-label-simple {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.646465px;
  color: var(--color-ui-dark);
  border: 0;
}

.paragraph {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.727273px;
  color: var(--color-ui-dark);
}

.input-field-label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.636364px;
}

.paragraph-small {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  align-items: center;
  color: var(--color-ui-dark);
}

.pharmacy-details table td {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: inherit;
}

.small-info-label {
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
}

.validation-error {
  color: var(--color-ui-red);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.727273px;
}

.info-text-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 21.86px;
  letter-spacing: 0.65px;
}

.info-text {
  margin-top: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.73px;
}

/* ------------------------------------------- buttons ------------------------------------------- */

.btn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.727273px;
  text-transform: uppercase;
  color: white;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.4;
}

.btn-adoptable {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  text-transform: inherit;
  border: 0;
}

.btn-simple {
  background-color: transparent;
  color: var(--color-ui-primary);
  border: 0;
  padding: 0;
}

.btn-link, .btn-link:hover, .btn-link:focus {
  text-decoration: none;
  text-transform: initial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.727273px;
  color: var(--color-ui-primary);
}

.btn-link-red, .btn-link-red:hover, .btn-link-red:focus {
  text-decoration: none;
  text-transform: initial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.727273px;
  color: var(--color-ui-red);
}

.btn-simple-dark {
  background-color: transparent;
  text-transform: initial;
  color: inherit;
  border: 0;
  padding: 0;
}

.btn_add {
  font-size: 40px;
  color: var(--color-ui-primary);
}

.btn-plusminus {
  font-size: 25px;
  color: var(--color-ui-navbar-dark);
}

.btn-remove {
  font-size: 40px;
  color: var(--color-ui-red);
}

.btn-primary {
  background-color: var(--color-ui-primary);
  border-width: 1px;
  border-color: var(--color-ui-primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.btn-secondary, .btn-secondary:hover {
  color: var(--color-ui-navbar-medium);
  background-color: var(--color-ui-button-light-bg);
  border: 1px solid #f0f2f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-terciery {
  background-color: var(--color-ui-navbar-medium);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-terciery-outlined {
  border-color: var(--color-ui-navbar-medium);
  border-width: 1px;
  color:  var(--color-ui-navbar-medium);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.btn-terciery:hover {
  background-color: var(--color-ui-navbar-dark);
  color: white;
}

.btn-red {
  background-color: var(--color-ui-red);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-red:hover {
  color: white;
  background-color: red;
}

.btn-social, .btn-social:hover {
  color: var(--color-ui-dark);
  background-color: white;
  border: 1px solid #A7AFBA;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-null {
  background-color: transparent;
  font-size: inherit;
  width: 100%;
  text-transform: none;
  padding: 0;
}

.btn-tag {
  background: var(--color-ui-navbar-medium);
  border-radius: 10px;
  text-transform: lowercase;
}

/* ------------------------------------------- toggles ------------------------------------------- */

.toggle-add {
  color: white;
  background-color: var(--color-ui-primary);
  border-radius: 4px;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  line-height: 0;
}

.toggle-kebab {
  color: var(--color-ui-navbar-medium);
  background-color: transparent;
  padding: 8px;
  font-size: 24px;
  cursor: pointer;
  line-height: 0;
}

.frame {
  padding-bottom: 100px;
}

.side_bar {
  background-color: var(--color-ui-light-blue);
  border-width: 0;
}

.width {
  width: 100%;
}

.statusFont {
  font-size: 15px;
}

.question {
  color: #dc6d13;
  font-size: 30px;
}

.badge-primary {
  background-color: var(--color-ui-primary);
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.badge-danger {
  background-color: var(--color-ui-red);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.badge-warning {
  background-color: var(--color-ui-warning);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.badge-dark {
  background-color: var(--color-ui-dark);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.badge-success {
  background-color: var(--color-ui-category-color-2);
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.badge-simple {
  background-color: var(--color-ui-navbar-medium);
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
}

.pavatar {
  border-width: 0;
  border-radius: 20px;
  text-align: center;
  padding: 40px;
  padding-bottom: 80px;
  padding-top: 80px;
  color: white;
  background-color: var(--color-ui-text-light);
}

.billing {
  padding: 10px;
  border-radius: 25px;
  background-color: #fff9e8;
}

.bg-light {
  background-color: white !important;
  border-bottom: 0;
  border-style: solid;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-color: #ccc;
  box-shadow: 0 0 12px rgba(85, 85, 85, 0.24);
}

.bg-globalvet {
  background-color: var(--color-ui-navbar-medium);
}

.bg-mobile {
  background-color: white;
}

.imageProfile {
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.img-circle {
  border-radius: 30px;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.profilePicture:hover .imageProfile {
  opacity: 0.3;
}

.profilePicture:hover .middle {
  opacity: 1;
}

.fix-container {
  width: 100%;
  padding-right: 2%;
  padding-left: 2%;
  margin-right: auto;
  margin-left: auto;
}

.striped-table {
  width: 100%;
  border-spacing: 0;
}

.striped-table td {
  padding: 0.5rem;
}

.simple-table td {
  padding: 0.3rem;
}

.table td,
.table th {
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #eee;
  border-style: solid none;
  border-width: 1px 0;
  margin: 10px 0;
}

.rotate {
  font-size: 10px;
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-rl;
  transform: rotate(-360deg);
}

.general-photo-bubble {
  padding: 3px;
  border-radius: 50%;
  background-color: var(--color-ui-button-light-bg);
}

.round-pet-photo-bubble {
  width: 80px;
  height: 80px;
}

.round-pet-photo-bubble-small {
  width: 40px;
  height: 40px;
}

.round-pet-photo-bubble-mobile {
  width: 50px;
  height: 50px;
}

/* ------------------------------------------- custom inputs ------------------------------------------- */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: var(--color-ui-text-placeholder);
  opacity: 1; /* Firefox */
}

.typeahead::placeholder {
  font-style: italic;
}

.login_radio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.login_radio [type="radio"] + i {
  cursor: pointer;
}

.login_radio [type="radio"]:checked + i {
  border-radius: 50px;
  padding: 10px;
  color: white;
  background-color: var(--color-ui-primary);
}

.login_radio {
  color: var(--color-ui-navbar-medium);
  text-align: center;
  font-size: 36px;
  padding: 10px;
}

.form-control{
  background: var(--color-ui-input-bg);
  border: 1px solid var(--color-ui-text-placeholder);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 6px 13px;
  color: var(--color-ui-dark);
  box-shadow: none;
}

.form-control:focus {
  background: var(--color-ui-input-bg);
  border: 1px solid var(--color-ui-primary);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--color-ui-dark);
  box-shadow: none;
}

.form-control:hover {
  border: 1px solid var(--color-ui-dark);

}

.react-tel-input .form-control {
  background: var(--color-ui-input-bg) !important;
  border: 1px solid var(--color-ui-text-placeholder) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  width: inherit !important;
  height: calc(2.25rem + 2px) !important;
}

select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
}

.input:active, input:focus {
  outline: none;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child), .input-group > .rbt:not(:last-child) .form-control {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.error-form-control-input {
  border-color: var(--color-ui-red) !important;
}

.input-group-text {
  background-color: var(--color-ui-input-bg);
  border-color: var(--color-ui-text-placeholder);
}

.input-group:focus>.input-group-text {
  border-color: var(--color-ui-navbar-medium);
}

.custom-form-control-input {
  background: var(--color-ui-input-bg);
  border: 1px solid #9c9ea0;
  box-sizing: border-box;
  border-radius: 5px;
}

.custom-form-control-select {
    background-color: var(--color-ui-select-bg);
    border: 1px solid var(--color-ui-navbar-medium);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--color-ui-navbar-medium);
}

.custom-form-control-select:focus {
  background-color: var(--color-ui-select-bg);
  border: 1px solid var(--color-ui-primary);
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--color-ui-navbar-medium);
}

.custom-form-control-select:hover {
  border-color: var(--color-ui-dark);
}

select.classic, select.classic:focus {
  /*
  background-image: url("./arrow-down.svg");
  background-position: right center;
  background-repeat: no-repeat;
   */
  background-size: 40px;
  padding-right: 28px;
 
}

.form-control[readonly] {
  background-color: #f5f5f5;
}

.react-phone-input {
  font-family: inherit !important;
}

.react-tel-input .error.form-control {
  border: 1px solid var(--color-ui-red) !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid var(--color-ui-text-placeholder) !important;
}

.react-tel-input .country-list {
  border: 1px solid var(--color-ui-text-placeholder) !important;
  box-shadow: 0 0 5px lightgray !important;
  border-radius: 5px !important;
}

/* typeahead */

.typeahead .form-control {
  background: var(--color-ui-input-bg);
  border: 1px solid var(--color-ui-text-placeholder);
  box-sizing: border-box;
  border-radius: 5px;
}

.rbt-token {
  background-color: var(--color-ui-red)!important;
  border-radius: 10px!important;
  color: white!important;
  font-size: 10px;
}

.rbt-menu > li a {
  padding: .375rem .75rem;
  font-size: 14px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--color-ui-red);
  background-image: none;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: var(--color-ui-red);
  box-shadow: 0 0 0 0;
}

.form-row {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 24px;
}

.numeric-input .form-row {
  display: block;
}

.page-title-padding {
  padding-top: 12px;
  padding-bottom: 32px;
}

.bold {
  font-weight: bold;
}

/* --------------------------------------------------------- DROPDOWNS -------------------------------------------- */
.menu-drop-bg {
  background-color: var(--color-ui-navbar-dark);
}

.dropdown-menu-appointment-search {
  background-color: white;
  border: 0;
  border-radius: 0 0 5px 5px;
  background-clip: border-box;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 1060;
}

.dropdown-search {
  z-index: 1060;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  background-color: white;
  position: absolute;
  min-width: 19.5rem;
  border-radius: 0 5px 5px 5px;
  top: 24px;
  left: -312px;
  padding: 0;
}

.rounded-dropdown .dropdown-menu,
.dropleft .dropdown-menu {
  border-radius: 5px 5px 5px 5px;
}

.dropleft .dropdown-toggle::before {
  display: none;
}

.notification .dropdown-menu {
  min-width: 13rem;
}

.dropdown-item {
  color: var(--color-ui-dark);
}

.dropdown-item.disabled {
  color: var(--color-ui-text-light) !important;
}

.dropdown-item-appointment-search {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--color-ui-dark);
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #F5F6F6;
}

.calendar-menu.dropdown-item {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.727273px;
  color: var(--color-ui-primary);
  padding: 0.75rem 1rem 0.75rem 1rem;
  cursor: pointer;
}

/* --------------------------------------------------------- CARDS -------------------------------------------- */

.card {
  border: 1px solid var(--color-ui-block-border);
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
}

/* --------------------------------------------------------- ALERTS -------------------------------------------- */

.errorArea {
  background: rgba(243, 118, 118, 0.1);
  color: var(--color-ui-red);
  border: 1px solid rgba(243, 118, 118, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
}

.successArea {
  background: rgba(88, 203, 125, 0.1);
  border: 1px solid rgba(88, 203, 125, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--color-ui-category-color-2);
  padding: 16px;
}

.hr-on-top {
  border-top: 1px solid #dadee3;
}

.hr-on-bottom {
  border-bottom: 1px solid #dadee3;
}

.no-border-on-top {
  border-top: 0 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.table-hover tbody tr:hover {
  background-color: #fafafa;
}

.dropdown-toggle::after {
  content: none;
}

.badge {
  border-radius: 5rem;
}

.badge-large {
  font-size: 14px;
}

.fa-bell {
  font-size: 1.3rem;
}

sup {
  top: -0.9em;
}

.settings-tabs .nav-tabs .nav-link {
  background-color: var(--color-ui-block-border);
  border-color: #fff #fff #dee2e6 #fff;
  border-radius: 10px 10px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.727273px;
  text-transform: uppercase;
  color: white;
  min-width: 160px;
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-tabs .nav-tabs .nav-link.active {
  background-color: var(--color-ui-navbar-dark);
  border-color: #fff #fff #dee2e6 #fff;
  color: white;
}

.stat-tabs .nav-tabs .nav-link {
  background-color: white;
  color: var(--color-ui-navbar-dark);
}

#stat-tabs .nav-tabs .nav-link.active {
  background-color: white;
  color: var(--color-ui-navbar-dark);
  border-color: #dee2e6 #dee2e6 #fff #dee2e6;
}

.map-modal {
  min-height: 420px;
}

/* ------------------------------------------------ COLORS ------------------------------------------ */

.color-light-grey {
  color: var(--color-ui-navbar-medium);
}

.color-input-bg {
  color: var(--color-ui-input-bg);
}

.color-green {
  color: var(--color-ui-category-color-2);
}

.color-dark {
  color: var(--color-ui-dark);
}

/* -------------------------------------- Navbar -------------------------------------- */

.navbar-dark .navbar-toggler {
  color: white;
  border-color: transparent;
}

button:focus {
  outline: transparent;
}

.navbar-collapse {
  text-align: center;
}

@media (min-width: 30rem) {
  [data-md~="clinic-dropdown-minwidth"] {
    min-width: 320px;
  }

  #dropdown-navbar {
    min-width: 260px;
  }
}

.menu-dropdown-right .dropdown-menu {
  right: 0;
  left: auto;
}

.my-pets-nav-dropdown .dropdown-menu {
  margin-top: 2px !important;
}

/* --------------------------------------------- FullCalendar --------------------------- */

.fc-button {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.727273px;
  text-transform: uppercase;
  color: white !important;
}

.fc-button-primary {
  color: inherit !important;
  background-color: transparent !important;
  border: 0 !important;
  display: inline;
}

.fc-toolbar h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 33px;
  align-items: center;
  vertical-align: middle;
  color: var(--color-ui-dark);
  display: inline;
  margin-left: 10px !important;
}

.fc-button-primary:focus {
  box-shadow: none !important;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}

.react-date-picker--disabled {
  opacity: 0.5;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *::before,
.react-date-picker *::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::placeholder {
  color: rgb(212, 212, 216);
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 0px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var(--color-ui-primary);
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *::before,
.react-calendar *::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: var(--color-ui-primary);
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--color-ui-primary);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.5;
}

/* --------------------------------------------- google-maps-react --------------------------- */
.gm-style-iw-d {
  overflow: auto !important;
}

.gm-style-iw.gm-style-iw-c {
  padding: 12px 22px !important;
}

.gm-style-iw.gm-style-iw-c > button {
  top: -2px !important;
  right: -2px !important;
}

/* --------------------------------------------- custom scrollbar --------------------------- */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #eee;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--color-ui-navbar-medium);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  background: var(--color-ui-navbar-medium);
  border-radius: 3px;
}

/* --------------------------------------------- Chat --------------------------- */

.Messages::-webkit-scrollbar-thumb {
  background: var(--color-ui-navbar-medium);
  border-radius: 3px;
}

.Messages {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 6px;
}

.Message {
  margin-bottom: 20px;
}

.Message-body {
  max-width: 80%;
  display: inline-block;
  background-color: var(--color-ui-input-bg);
  border-radius: 10px;
}

.Message.Message-fromMe {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.Message.Message-fromMe .Message-body {
  background-color: var(--color-ui-primary);
  color: white;
}

.Message .Message-date {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }